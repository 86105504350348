
import { Head, useForm } from '@inertiajs/react';
import PrimaryButton from '@/Components/PrimaryButton';
import React, {useRef, useState, useEffect} from 'react';
import { Link } from '@inertiajs/react';
import Image from '../../Book/Partials/Image';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faBars } from '@fortawesome/free-solid-svg-icons';

export default function SeriesCard(props) {
    console.log(props);
    const isOwner = (props.series.user_id == props.user);
    const imageUrl = props.series.open_library_key !== undefined ? "https://covers.openlibrary.org/b/olid/" + props.series.open_library_key.substr(7) + "-M.jpg" : '';

    const shortDescription = props.series.description ? (props.series.description.length > 100 ? props.series.description.slice(0, 100) + '...' : props.series.description) : 'No description provided';

    return (
        <div className="card">
            <Image key={props.series.isbn} coverUrl={imageUrl} fallbackUrl="/storage/nocover.png" />
            <div className="details">
                <p className="name">
                    {props.series.name}
                </p>
                <p className="description">
                    {shortDescription}
                </p>
                <p className="count">
                Contains {props.series.books_count} books
                </p>
                <div className="grow"></div>
                {/* <div className="buttons flex"> */}
                    {isOwner ?
                            <Link href={route('series.edit', {id:props.series.id})}>
                                <PrimaryButton className="green">
                                    <FontAwesomeIcon icon={faEdit} /> Edit List
                                </PrimaryButton>
                            </Link>
                        :
                            <Link href={route('series.show', {id:props.series.id})}>
                                <PrimaryButton className="blue">
                                    <FontAwesomeIcon icon={faBars} rotation={90} /> View List
                                </PrimaryButton>
                            </Link>
                    }
                {/* </div> */}
            </div>
        </div>
    );
}
